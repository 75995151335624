import React, {useEffect} from 'react';
import { Container, Button, Box, Typography, Grid, Card as MuiCard, CardContent as MuiCardContent, } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logo from '../assets/images/logos/logo1.png';
import {styled} from '@mui/system';


const services = [
  { title: "Unlimited MVP Applications", description: "Tailored solutions for businesses to rapidly prototype their ideas." },
  { title: "Subscription-Based Model", description: "Flexible and predictable pricing with no hidden costs." },
  { title: "Web Development & Design", description: "High-quality web design and development services." },
  { title: "Unlimited Requests", description: "Submit as many requests as you need - we've got you covered." },
  { title: "Cancel Anytime", description: "No long-term contracts, cancel your subscription anytime." }
];

const Card = styled(MuiCard)(({ theme }) => ({
  backgroundColor: '#8906E6', // Keeping your color preference
  transition: "transform 0.15s ease-in-out", // Smooth transition for hover effects
  '&:hover': {
    transform: "scale(1.05)", // Scale up slightly on hover
    boxShadow: "0 6px 20px rgba(0, 0, 0, 0.5)", // Increase shadow on hover
  }
}));

const CardContent = styled(MuiCardContent)(({ theme }) => ({
  color: 'white',
  '& .MuiTypography-h6': {
    marginBottom: theme.spacing(2), // Consistent spacing below the title
    fontWeight: 'bold', // Bold title
    letterSpacing: '0.5px', // Enhanced letter spacing for readability
  },
  '& .MuiTypography-body1': {
    lineHeight: '1.5', // Improved line height for body text
  }
}));

const ServiceOverview = ({scrollToComponent}) => {
    useEffect(() => {
        AOS.init({duration: 1200});
      }, []);
      const handleButtonClick = () => {
        scrollToComponent.current.scrollIntoView({ behavior: 'smooth'})
      }
  return (
    <Box sx={{ backgroundColor:'#100D28', flexGrow: 1, padding: '2rem' }}>
      <div id="service-overview"></div>
      {/* <Typography variant="h4" gutterBottom align="center">
        Our Services
      </Typography> */}
      <img src={logo} alt="Logo" style={{ width: '80px', height: 'auto'}}></img>
      <Grid container spacing={4} justifyContent="center">
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
           <Box sx={{
              transition: "transform 0.15s ease-in-out",
              '&:hover': {
                transform: "scale(1.05)",
                boxShadow: "0 6px 20px rgba(0, 0, 0, 0.5)",
              }
            }}>
              <MuiCard raised sx={{ backgroundColor: '#8906E6' }} data-aos="zoom-in">
                <MuiCardContent sx={{
                  color: 'white',
                  '& .MuiTypography-h6': {
                    marginBottom: 2,
                    fontWeight: 'bold',
                    letterSpacing: '0.5px',
                  },
                  '& .MuiTypography-body1': {
                    lineHeight: 1.5,
                  }
                }}>
                  <Typography variant="h6" gutterBottom>
                    {service.title}
                  </Typography>
                  <Typography variant="body1">
                    {service.description}
                  </Typography>
                </MuiCardContent>
              </MuiCard>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Container sx={{
        textAlign: 'center',
      }}>
            <Button sx={{
                margin:"32px", marginBottom:"32px", backgroundColor:'#050DEB', color:'white', '&:hover': {
                  backgroundColor: '#C484F1', // This will be applied on hover
                  color: 'white', // Optional: changing text color on hover 
                },
            }} size="large" data-aos="zoom-in" variant="contained" color="primary" href="https://calendly.com/eugenese/let-s-talk-solutions">
                Book a Call
            </Button>
            <Button sx={{
                margin:"32px", marginBottom:"32px", backgroundColor:'#050DEB', color:'white', '&:hover': {
                  backgroundColor: '#C484F1', // This will be applied on hover
                  color: 'white', // Optional: changing text color on hover 
                },
            }} size="large" data-aos="zoom-in" variant="contained" color="primary" onClick={handleButtonClick}>
                Meet the Team
            </Button>
      </Container>
      
    </Box>
  );
};

export default ServiceOverview;
