import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Typography, Grid} from '@mui/material';
import {styled} from '@mui/system';

const FooterContainer = styled(Box)({
    backgroundColor: '#100D28',
    padding: '40px 0',
})

const FooterLink = styled(Link)({
    color: '#050DEB',
    textDecoration: 'none',
    '&:hover':{
        color: 'white',
    },
})

const FooterText = styled(Typography)({
    color: 'white',
    marginBottom: '20px',

});

const FooterBentoBox = styled(Box)({
    backgroundColor: '#C484F1',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
  });



const Footer = () => {
    return (
        <FooterContainer>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <FooterBentoBox>
                <FooterText variant="h6">Support</FooterText>
                <FooterLink to="/customer-support">Customer Support</FooterLink>

              </FooterBentoBox>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FooterBentoBox>
                <FooterText variant="h6">Contact Us</FooterText>
                <Typography variant="body2" color="white">
                  Email: eugene@infinimvp.com<br />
                  {/* Phone: +1 123-456-7890 */}
                </Typography>
              </FooterBentoBox>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FooterBentoBox>
                <FooterText variant="h6">Quick Links</FooterText>
                <FooterLink to="/privacy-policy">Privacy Policy</FooterLink><br />
                <FooterLink to="/terms-and-conditions">Terms of Service</FooterLink>
                
              </FooterBentoBox>
            </Grid>
          </Grid>
          <Typography variant="body2" color="#8906E6" align="center" mt={4}>
            &copy; {new Date().getFullYear()} InifiniMVP. All rights reserved.
          </Typography>
        </FooterContainer>
      );


};

export default Footer;
