import React, {useEffect} from 'react';
import { Box, Grid, Typography, Button, Avatar, Divider } from '@mui/material';
import { Web, Code, Storage, ChargingStation } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import headshotimg from '../assets/headshot.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import credImage from '../assets/images/2024_Redesign/azure-fundamentals.png'


const UserProfileSection = ({scrollToComponent}) => {

  useEffect(() => {
    AOS.init({duration: 1200});
  }, []);
  return (
    
    <Box sx={{ bgcolor: '#C484F1', p: 3, borderRadius: 2, boxShadow: 3, my: 4 }}>
      <Divider style={{ margin: '20px 0' }} />
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Avatar data-aos="fade-up"
            alt="Eugene"
            src={headshotimg} // Replace with your headshot URL
            sx={{ width: 200, height: 200, mx: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={9} textAlign={{ xs: 'center', sm: 'left' }}>
          <Typography data-aos="slide-left" sx={{color:'white', fontWeight: 'bold', fontFamily: 'CustomFont, Arial'}} variant="h4" gutterBottom>
            Hi, I'm Eugene
          </Typography>
          <Typography data-aos="slide-left" sx={{color:'white'}} variant="subtitle1" gutterBottom>
            "Your Full-Stack Software Engineer."
          </Typography>
          {/* Icons representing expertise */}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, my: 2 }}>
            <Web sx={{ color: 'white' }} fontSize="large" />
            <Typography sx={{color:'white'}} variant="body1">
              Frontend Development
            </Typography>

            <Code sx={{ color: 'white' }} fontSize="large" />
            <Typography sx={{color:'white'}} variant="body1">
              Backend Development
            </Typography>

            <Storage sx={{ color: 'white' }} fontSize="large" />
            <Typography sx={{color:'white'}} variant="body1">
              Database Management
            </Typography>

            <ChargingStation sx={{ color: 'white' }} fontSize="large" />
            <Typography sx={{color:'white'}} variant="body1">
              Blockchain & AI
            </Typography>
            
          </Box>
          <Box>
            <Button data-aos="fade-up"
              
              href="https://www.linkedin.com/in/eugene-brown-jr-38210b99/" // Replace with your LinkedIn URL
              target="_blank"
              sx={{ mr: 1, backgroundColor:'#050DEB', color: 'white'}}
            >
              LinkedIn
            </Button>
            <Button data-aos="fade-up"
              
              href="https://github.com/builde7b0b" // Replace with your GitHub URL
              target="_blank"
              sx={{ mr: 1, backgroundColor:'#050DEB', color: 'white'}}
            >
              GitHub
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider style={{ margin: '20px 0' }} />
    </Box>
  );
};

export default UserProfileSection;
