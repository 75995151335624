import React from 'react';

const TermsAndConditions = () => {
  return (
    <div>
      <h2>Introduction</h2>
      <p>
        By accessing or using InfiniMVP's website, you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, please do not use our service.
      </p>

      <h2>Use of Service</h2>
      <p>
        InfiniMVP provides subscription-based development services. You agree to use our services only for lawful purposes and in compliance with these terms.
      </p>

      <h2>Intellectual Property</h2>
      <p>
        The service and its original content, features, and functionality are and will remain the exclusive property of InfiniMVP.
      </p>

      <h2>User Conduct</h2>
      <p>
        You agree not to use the service to:
      </p>
      <ul>
        <li>Transmit any malicious code or data.</li>
        <li>Engage in any activity that disrupts or damages the service.</li>
        <li>Violate any applicable laws or regulations.</li>
      </ul>

      <h2>Termination</h2>
      <p>
        We may terminate or suspend access to our service immediately, without prior notice, for any reason whatsoever, including, without limitation, if you breach the Terms.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
        In no event shall InfiniMVP, nor its directors, employees, or partners, be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of our service.
      </p>

      <h2>Governing Law</h2> <p>These Terms shall be governed by the laws of the State of Arizona, United States, without regard to its conflict of law provisions. The Company is headquartered in Arizona, and Arizona law shall govern any disputes or legal proceedings arising from these Terms or your use of our services, regardless of your location.</p> <p>Please note that while we work with clients globally, by accessing or using our services, you agree that any disputes or claims arising out of or relating to these Terms or your use of our services will be governed by Arizona law and will be resolved exclusively in the state or federal courts located in Arizona.</p>

      <h2>Changes</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
      </p>

      <h2>Contact Us</h2>
      <p>
        For any questions or concerns regarding these Terms, please contact us at eugene@infinimvp.com.
      </p>
    </div>
  );
};

export default TermsAndConditions;
