import React, {useEffect} from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemIcon, Button, CardActions, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star'; // For the 'Most Popular' icon
import 'aos/dist/aos.css';
import AOS from 'aos';
// Import icons
import TimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PaymentIcon from '@mui/icons-material/Payment';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';


// Mapping features to icons
const featureIcons = {
  'One request at a time': TimeIcon,
  'Average 2-week delivery': TimeIcon,
  'Average 1-week delivery': TimeIcon,
  'Unlimited brands': PeopleIcon,
  'Unlimited users': PeopleIcon,
  'Unlimited stock photos and video elements': PhotoLibraryIcon,
  'Easy Credit-Card payments': PaymentIcon,
  'Pause or Cancel anytime': PauseCircleOutlineIcon,
  'Double requests': TimeIcon,
  'Free Design Sprint (1-Week Delivery)': TimeIcon,
};
const tiers = [
  {
    name: 'Standard',
    price: '$1,000 /monthly',
    features: [
      'One request at a time',
      'Average 2-week delivery',
      'Unlimited brands',
      'Unlimited users',
      'Unlimited stock photos and video elements',
      'Easy Credit-Card payments',
      'Pause or Cancel anytime',
    ],
    isPopular: true,
    link: 'https://buy.stripe.com/9AQ02t30S4nhb6gfYY'
  },
  {
    name: 'MVP',
    price: '$3,500 /monthly',
    features: [
      'Double requests',
      'Free Design Sprint (1-Week Delivery)',
      'Average 1-week delivery',
      'Unlimited brands',
      'Unlimited users',
      'Unlimited stock photos and video elements',
      'Easy Credit-Card payments',
      'Pause or Cancel anytime',
    ],
    isPopular: false,
    link: 'https://buy.stripe.com/3cs3eFatk7ztcak3cd',
  },
];

const TierComponent = ({scrollToComponent}) => {
  useEffect(() => {
    AOS.init({duration: 1200});
  }, []);

  
  return (
    <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
        <Typography sx={{fontWeight: 'bold', fontFamily: 'CustomFont, Arial', color: '#100D28'}}  data-aos="slide-up" variant="h2">Choose Your Tier</Typography>
      {tiers.map((tier, index) => (
        <Card data-aos="flip-right" key={index} sx={{ maxWidth: 345, margin: 2, backgroundColor:'#8906E6', position: 'relative', boxShadow: 3 }}>
          {tier.isPopular && (
            <Box position="absolute" top={-10} right={-10} bgcolor="gold" borderRadius="50%" padding={1}>
              <StarIcon  />
            </Box>
          )}
          <CardContent sx={{color:'white'}}>
            <Typography sx={{animation: 'pulseAnimation 2s infinite'}} variant="h5" component="div">
              {tier.name} - {tier.price}
            </Typography>
            <List>
              {tier.features.map((feature, idx) => {
                const Icon = featureIcons[feature];
                return (
                  <ListItem key={idx}>
                    <ListItemIcon>
                      <Icon sx={{ color: 'gold' }} />
                    </ListItemIcon>
                    <Typography variant="body2">{feature}</Typography>
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
          <CardActions sx={{display: 'flex', justifyContent: 'center',}}>
            <Button href={tier.link} sx={{backgroundColor: 'gold', fontSize: '1.25rem',
                animation: 'pulseAnimation 2s infinite',
                    backgroundColor: '#050DEB', // This will be applied on hover
                    color: 'white', // Optional: changing text color on hover
                  }}  variant="contained" size="large">Choose {tier.name}</Button>
          </CardActions>
        </Card>
      ))}
    </Box>
  );
};

export default TierComponent;
