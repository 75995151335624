import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h2>Introduction</h2>
      <p>
        Welcome to InfiniMVP! We are committed to protecting your privacy and handling your personal information transparently. This policy outlines the types of information we collect, how we use it, and the measures we take to protect it.
      </p>

      <h2>Information Collection and Use</h2>
      <h3>Personal Information:</h3>
      <p>
        We collect personal information you provide when you sign up for our services, such as your name, email address, and payment details. This information is used to deliver our services, process transactions, and communicate with you.
      </p>
      <h3>Usage Data:</h3>
      <p>
        We automatically collect information on how you interact with our website, such as IP addresses, browser types, and pages visited. This data helps us improve our services and user experience.
      </p>

      <h2>Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar technologies to enhance your experience on our site, analyze our traffic, and provide personalized content and ads.
      </p>

      <h2>Sharing and Disclosure</h2>
      <p>
        We do not sell or rent your personal information to third parties. We may share your information with service providers who assist us in delivering our services, and when required by law.
      </p>

      <h2>Security</h2>
      <p>
        We implement robust security measures to protect your information from unauthorized access, alteration, and loss.
      </p>

      <h2>Your Rights</h2>
      <p>
        You have the right to access, correct, or delete your personal information. Please contact us to exercise these rights.
      </p>

      <h2>Changes to This Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at eugene@infinimvp.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
