import './App.css';
import React, {useRef} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {Link} from 'react-router-dom';
import { createTheme, ThemeProvider, AppBar, Toolbar } from '@mui/material';
import logo1 from './assets/images/logos/infini-white.png';

import HeroSection from './components/Hero';
import ServiceOverview from './components/ServiceOverview';
import Pricing from './components/Pricing';
import Gallery from './components/Gallery';
import Integrations from './components/Integrations';
import ScopeOfWork from './components/ScopeOfWork';
import CompanyTicker from './components/CompanyTicker';
import HorizontalScroll from './components/HorizontalScroll';
import TierComponent from './components/TierComponent';
import CaseStudiesComponent from './components/CaseStudiesComp';
import FAQComponent from './components/FAQComponent';
import CaseStudiesComponentX from './components/CaseStudiesComponent';
import ContactFormComponent from './components/ContactFormComponent';
import UserProfileSection from './components/UserProfileComp';

import PrivacyPolicy from './components/legal/PrivacyPolicy';
import TermsAndConditions from './components/legal/TermsAndConditions';
import Footer from './components/Footer';
import CustomerSupport from './components/CustomerSupport';

// Step 1: Create a custom theme
const theme = createTheme({
  typography: {
    allVariants: {
      fontWeight: 'bold', // This applies bold styling to all Typography variants
    },
    components: {
      Button: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'green',
            },
          },
        },
      },
    },
  },
  palette: {
    // Define your palette here
    primary: {
      main: '#556cd6',
    },
  },
});

function App() {
  const serviceOverviewRef = useRef(null);
  const tiersRef = useRef(null);
  const caseStudiesRef = useRef(null);
  const userProfileRef = useRef(null);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AppBar sx={{ backgroundColor: 'white' }} position="sticky">
          <Toolbar sx={{ backgroundColor: 'white' }}>
            <Link to="/">
            <img src={logo1} alt="Logo" style={{ width: '80px', height: 'auto' }} />
            </Link>
          </Toolbar>
        </AppBar>
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection scrollToComponent={serviceOverviewRef} />
              <div ref={serviceOverviewRef}>
                <ServiceOverview scrollToComponent={userProfileRef} />
              </div>
              <CompanyTicker />
              <Gallery />
              <Integrations />
              <Pricing scrollToComponent={tiersRef} />
              <div ref={tiersRef}>
                <TierComponent />
              </div>
              <HorizontalScroll />
              <ScopeOfWork />
              <CaseStudiesComponent scrollToComponent={caseStudiesRef} />
              <FAQComponent />
              <div ref={userProfileRef}>
                <UserProfileSection />
              </div>
              <div ref={caseStudiesRef}>
                <CaseStudiesComponentX />
              </div>
              <ContactFormComponent />
            </>
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/customer-support" element={<CustomerSupport />} />

        </Routes>
        <Footer />
      </ThemeProvider>
    </Router>
  );
}

export default App;