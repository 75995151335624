import React from 'react';
import { Typography, Box } from '@mui/material';

const CustomerSupport = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Customer Support
      </Typography>
      <Typography variant="body1">
        If you have any questions or need assistance, please contact us at:
      </Typography>
      <Typography variant="body1">
        Email: eugene@infinimvp.com
      </Typography>
      {/* Add your contact form component here */}
    </Box>
  );
};

export default CustomerSupport;