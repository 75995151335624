import React, { useEffect, useRef } from 'react';
import { Container, Typography, Button, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../styles/Hero.css';

const HeroSection = ({ scrollToComponent }) => {
  useEffect(() => {
    // JavaScript animation for text typing effect
    const textTypingAnimation = () => {
      const text = "Empower Your Business with 'InfiniMVP'";
      const typingSpeed = 100; // Speed in milliseconds
      const textElement = document.querySelector('.typing-text');
  
      let charIndex = 0;
  
      // Clear text content before starting animation
      textElement.innerHTML = '';
  
      const type = () => {
        // Check if the last character of the text content is the same as the current character
        if (textElement.textContent.slice(-1) !== text.charAt(charIndex)) {
          textElement.innerHTML += text.charAt(charIndex);
        }
        charIndex++;
        if (charIndex < text.length) {
          setTimeout(type, typingSpeed);
        }
      };
      
  
      type();
    };
  
    textTypingAnimation();
  }, []);
  
  const handleButtonClick = () => {
    scrollToComponent.current.scrollIntoView({ behavior: 'smooth' });
  };

  

  return (
    
    <Container maxWidth="lg" className="hero-container">
      <div id="hero"></div>
      <div className="hero-background" />
      <div className="hero-overlay" />
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <header>
            <Typography sx={{fontWeight: 'bold', fontFamily: 'CustomFont, Arial'}} className="typing-text" variant="h2" gutterBottom />
          </header>
          <Typography sx={{color:'white', }}  data-aos="fade-up" variant="h5" color="textSecondary" >
            From Concept to Code(MVP) in less time than you can say 1, 2, 3...
          </Typography>
          <Button sx={{
                margin:"32px", marginBottom:"32px", backgroundColor:'#050DEB', color:'white', '&:hover': {
                  backgroundColor: '#C484F1', // This will be applied on hover
                  color: 'white', // Optional: changing text color on hover 
                },
            }}
            onClick={handleButtonClick}
            variant="contained"
            color="primary"
            className="custom-button"
            size="large"
            endIcon={<ArrowForwardIcon />}
          >
            Get Started
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HeroSection;
